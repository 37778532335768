import React from 'react';
import CreditsList from '../components/creditsList'

const Credits = () => {
    return(
        <>
        <CreditsList></CreditsList>
        </>
    );
};

export default Credits;